import { CounterTypes, EnergySuppliers, ElectricityAndGasPrices, MeterUsage, SimulatePriceResponse } from 'features/simulation/types'
import { Language } from './language'
import { EnergyUsageGrade } from './usages'
import { MarketingData } from './marketing-params'
import { ELProduct, NGProduct } from './product-data'
import { ProspectAndCustomerProducer } from './producer'
import { PromoCode, ReferralCode } from './promo-referral-codes'
import { Situation } from 'features/registration/types'
import { ContactMoment, PreviousInhabitant } from 'store/customer/types'

export type Address = {
  countryCode: 'BE'
  postalCode: string
  townCode: number
  townName: string
  streetName: string
  streetNumber: string
  streetBox: string
}

export type Company = {
  name: string
  vatApplication: boolean | string
  enterpriseNumber: string
  legalForm: LegalForm
}

export type WarmProspectData = {
  firstName: string
  lastName: string
  email: string
  language: Language
  flow: WarmProspectFlow

  // Festival specific fields
  tieBreaker?: number
}

export type CreateProspectReturnType = {
  opportunityId: string
  accepted: boolean
}

export type CreateCustomerData = GeneralProspectAndCustomerData & {
  company?: {
    name: string
    vatApplication: boolean
    enterpriseNumber: string
    legalForm: LegalForm | null
  }
  contact: {
    contactMode: ContactMode
    email: string
    phoneMobile: string
  }
  contractStartDate: string // ISO date format
  correspondenceAddress: Address
  deliveryAddress: Address
  dynamicTariff: boolean
  invoicing: {
    paymentMethod: string
    ibanNumber: string
  }
  knowsMeterDetails: boolean
  meterInfo: {
    electricity: {
      EAN: string
      exclEAN: string
      indexDay: string
      indexNight: string
      indexExcl: string
      meterNumber: string
    }
    gas: {
      EAN: string
      index: string
      meterNumber: string
    }
    meterReadingMonth?: number
  }
  opportunityId: string
  person: {
    firstName: string
    lastName: string
    birthDate: string
  }
  previousSupplier?: EnergySuppliers
  priceListUrl: FuelPricelistUrls
  producer: ProspectAndCustomerProducer
  signatureDataUrl?: string
  situation: Situation
  uploadDataUrl?: string
}

type GeneralProspectAndCustomerData = {
  boltGoPrices?: ElectricityAndGasPrices
  counterType: CounterTypes
  energyUsageGrade?: EnergyUsageGrade
  fixedPrices?: ElectricityAndGasPrices
  forcedAmount?: number
  language: Language
  marketing: MarketingData
  meterUsage: MeterUsage
  price: SimulatePriceResponse['price']
  products: {
    electricity: ELProduct
    gas?: NGProduct
  }
  promoCode?: PromoCode
  referralCode?: ReferralCode
  solarKva?: number
  hasSolarPanels: boolean
  walloniaSolarPanelsSince2024?: boolean
  previousInhabitant?: PreviousInhabitant
}

export type CreateProspectData = GeneralProspectAndCustomerData & {
  companyName?: string
  contactMoment?: ContactMoment
  creditCheck?: boolean
  email: string
  enterpriseNumber?: string
  firstName: string
  isCompany: boolean
  lastName: string
  mobilePhone: string
  opportunityId?: string
  producer?: ProspectAndCustomerProducer
  zipcode: number
}

export type FuelPricelistUrls = {
  electricity: string
  gas: string
}

// ENUMS

export enum ClientType {
  PROFESSIONAL = 'Professional',
  RESIDENTIAL = 'Residential'
}

export enum ContactMode {
  EMAIL = 'EMAIL',
  POST = 'POST'
}

export enum LegalForm {
  ACP = 'ACP',
  VME = 'VME',
  BVBA = 'BVBA',
  SPRL = 'SPRL',
  BV = 'BV',
  SRL = 'SRL',
  NV = 'NV',
  SA = 'SA',
  GCV = 'GCV',
  SCS = 'SCS',
  COMMV = 'CommV',
  SCOMM = 'SComm',
  CVBA = 'CVBA',
  SCRL = 'SCRL',
  VZW = 'VZW',
  ASBL = 'ASBL',
  EBVBA = 'EBVBA',
  SPRLU = 'SPRLU',
  VOF = 'VOF',
  SNC = 'SNC',
  INDIVIDUAL_NL = 'Eenmanszaak',
  INDIVIDUAL_FR = 'Entreprise individuelle'
}

export enum WarmProspectFlow {
  FESTIVAL = 'festival',
  NEWSLETTER = 'newsletter'
}
